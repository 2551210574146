import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="Alexander Petkov - alexanderpetkov.net"
      />

      <div className="card business-card my-3">
        <h1 className="display-4 mt-3 font-weight-light text-monospace">Alexander Petkov</h1>
        <div className="container">
          <div className="row">
            <div className="col-sm px-0">
              <dl>
                <dt>Founder & CEO @ <a href="https://www.celantur.com" target="_blank" rel="noopener noreferrer" className="text-decoration-none">Celantur</a></dt>
                <dd className="font-weight-light">Preserving privacy on images and videos <span role="img" aria-label="lock">🔐</span></dd>
                <dt>Tech Enthusiast</dt>
                <dd className="font-weight-light">Inspired by technology since early childhood</dd>
                <dt>Citoyen</dt>
                <dd className="font-weight-light">Passionate about politics and society</dd>
              </dl>

            </div>
            <div className="col-sm px-0 d-flex align-items-end justify-content-end">
              <a href="https://linkedin.com/in/alexander-petkov-at/" className="btn btn-outline-light" target="_blank" rel="noopener noreferrer"><span role="img" aria-label="chat">💬</span> Send Alex a message </a>
            </div>
          </div>
        </div>

      </div>

      <section className="">
        <h2>Blog</h2>
        <p className="text-muted">I write about startups, technology and things that keep me busy.</p>
        <div class="row row-cols-1 row-cols-lg-2 mt-5">
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            const fluid = node.frontmatter.image.childImageSharp.fluid
            return (
              <div class="col mb-4 h-100" key={node.fields.slug}>
                <div class="card">
                  <Img fluid={fluid} class="card-img-top" alt="Blog Post Image" style={{ maxHeight: '200px' }} />
                  <div class="card-body">
                    <small>{node.frontmatter.date}</small>
                    <h3 class="card-title my-3 font-weight-bold">{title}</h3>
                    <p
                      className="card-text text-muted"
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                    <Link className="card-block stretched-link text-decoration-none" to={node.fields.slug} />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(maxHeight: 480, quality: 100) {
                   ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
